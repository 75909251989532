import { TransfertService } from 'src/app/transfert.service';
import { Operation, TransfertARealiser } from './../models/validation-transfert';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { getRangeLabelFr } from '../helpers';
import { MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit, AfterViewInit {

  public pagesSize: number[] = [20, 10, 50];

  validationsSelection = new SelectionModel<TransfertARealiser>(true, []);
  validationsDisplayedColumns: string[] = ['respInoveaFullName', 'emetteurFullName', 'beneficiaireFullName', 'beneficiaireValide', 'createdOn', 'isExecuted'];
  public validationsData = new MatTableDataSource<Operation>();
  @ViewChild(MatPaginator) validationsPaginator!: MatPaginator;
  validationsFilter: string = '';
  isLoadingResults = true;
  @ViewChild(MatSort) sort: MatSort = new MatSort();


  constructor(private service: TransfertService, private _matPaginatorIntl: MatPaginatorIntl) {
  }

  ngOnInit(): void {
    this.getValidations();
  }

  ngAfterViewInit(): void {
    this.validationsData.sort = this.sort;
  }

  private getValidations() {
    this._matPaginatorIntl.getRangeLabel = getRangeLabelFr;
    this._matPaginatorIntl.itemsPerPageLabel = "éléments par page: ";
    this.isLoadingResults = true;
    this.service.getValidations().subscribe((tar: TransfertARealiser[]) => {
      let mapped: any[] = [];
      if (tar) {
        tar.map((t: TransfertARealiser) => {
          let op: Operation = {
            id: t.validationId,
            respInoveaFullName: t.responsableInovea.fullName,
            emetteurFullName: t.commercialEmetteur.fullName,
            beneficiaireFullName: t.commercialBeneficiaire.fullName,
            beneficiaireValide: t.beneficiaireValide,
            createdOn: t.CreatedOn,
            isExecuted: t.isExecuted
          }
          mapped.push(op);
        });
      }
      this.validationsData = new MatTableDataSource<Operation>(mapped);
      this.validationsData.paginator = this.validationsPaginator;
      this.validationsData.sort = this.sort;
      this.isLoadingResults = false;
      this.validationsData.filterPredicate = function (record, filter) {
        return record.respInoveaFullName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        record.emetteurFullName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        record.beneficiaireFullName.toLowerCase().indexOf(filter.toLowerCase()) > -1
        ;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.validationsData.filter = filterValue.trim().toLowerCase();
  }

}
