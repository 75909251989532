import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private _config!: any;

    constructor(private http: HttpClient) { }

    get config(): any {
        return this._config;
    }

    set config(value: any) {
        this._config = value;
    }

    loadConfig(): Observable<any> {
        return this.http.get<any>('/assets/config.json');
    }
}


