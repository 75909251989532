<mat-card class="operation-container">
  <mat-form-field appearance="standard">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Dupont, dupont@gmail.com" #input [(ngModel)]="validationsFilter" [ngModelOptions]="{standalone: true}">
      <button *ngIf="validationsFilter" matSuffix mat-icon-button aria-label="Clear" (click)="validationsFilter='';validationsData.filter=''">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>

  <div class="results-spinner" *ngIf="isLoadingResults">
     <mat-spinner></mat-spinner>
  </div>

  <div class="example-table-container">
    <table mat-table [dataSource]="validationsData" matSort matSortDirection="desc" matSortActive="CreatedOn" matSortDisableClear class="mat-elevation-z8">
      <ng-container matColumnDef="respInoveaFullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </th>
        <td mat-cell *matCellDef="let element"> {{element.respInoveaFullName}} </td>
      </ng-container>

      <ng-container matColumnDef="emetteurFullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Commercial cédant </th>
        <td mat-cell *matCellDef="let element"> {{element.emetteurFullName}} </td>
      </ng-container>

      <ng-container matColumnDef="beneficiaireFullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Commercial cessionnaire </th>
        <td mat-cell *matCellDef="let element"> {{element.beneficiaireFullName}} </td>
      </ng-container>

      <ng-container matColumnDef="beneficiaireValide">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Le commercial est labellisé, son dossier est valide, il n'a pas encore démissionné"> Bénéficiaire Valide </th>
        <td mat-cell *matCellDef="let element" matTooltip="Le commercial est labellisé, son dossier est valide, il n'a pas encore démissionné">
          <ng-container *ngIf="element.beneficiaireValide; then thenTemplate; else elseTemplate"></ng-container>
          <ng-template #thenTemplate>
            <mat-icon class="green-icon">check_circle</mat-icon>
          </ng-template>
          <ng-template #elseTemplate>
            <mat-icon class="red-icon">cancel</mat-icon>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de validation </th>
        <td mat-cell *matCellDef="let element"> {{element.createdOn | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="isExecuted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Éxécuté </th>
        <td mat-cell *matCellDef="let element"> {{element.isExecuted ? "oui" : "non"}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="validationsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: validationsDisplayedColumns;" [routerLink]="'./' + row.id"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Aucune donnée ne correspond au filtre "{{input.value}}"</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="pagesSize" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>
