import { Personne } from './../../models/personne';
import { TransfertARealiser, ValidationTransfertContact, Execution, Correction, ValidationTransfertClient, ExecutionWithMail } from './../../models/validation-transfert';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TransfertService } from 'src/app/transfert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatInput } from "@angular/material/input";
import { getRangeLabelFr } from 'src/app/helpers';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent{
  public form: FormGroup;

  public transfert!: TransfertARealiser;
  public clients: ValidationTransfertClient[] = [];
  public filleuls: ValidationTransfertContact[] = [];
  public pagesSize: number[] = [20, 10, 50];
  public validationId: Execution[] = [];
  public isExecuted: boolean = false;


  clientsSelection = new SelectionModel<ValidationTransfertClient>(true, []);
  clientsDisplayedColumns: string[] = ['personne.fullName', 'personne.identifiant', 'beneficiaireCompatible','pasDImmoEnCours', 'select'];
  public clientsData!: MatTableDataSource<ValidationTransfertClient>;
  @ViewChild(MatPaginator) clientsPaginator!: MatPaginator;
  clientsFilter: string = '';

  filleulsDisplayedColumns: string[] = ['personne.fullName', 'personne.identifiant'];
  public filleulsData!: MatTableDataSource<ValidationTransfertContact>;
  @ViewChild(MatPaginator) filleulsPaginator!: MatPaginator;
  filleulsFilter: string = '';

  responsables: Personne[] = [];

  fileName: string = '';
  file64: string = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private service: TransfertService,
    private SnackBar: MatSnackBar,
    private _matPaginatorIntl: MatPaginatorIntl
  ) {
    _matPaginatorIntl.getRangeLabel = getRangeLabelFr;
    _matPaginatorIntl.itemsPerPageLabel = "éléments par page: ";
    this.form = this.fb.group({
      envoiMailCedant: [true],
      envoiMailCessionaire: [true]
    });
    const id = String(this.route.snapshot.paramMap.get('id'));
    this.service.getValidation(id).subscribe((tar: TransfertARealiser) => {
      this.transfert = tar;
    });
    this.service.getClientsValidation(id).subscribe((cs: ValidationTransfertClient[]) => {
      this.clients = cs;
      this.clientsData = new MatTableDataSource<ValidationTransfertClient>(this.clients);
      this.clientsData.filterPredicate = function (record,filter) {

        return record.personne.fullName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      }
    });
    this.service.getFilleulsValidation(id).subscribe((fs: ValidationTransfertContact[]) => {
      this.filleuls = fs;
      this.filleulsData = new MatTableDataSource<ValidationTransfertContact>(this.filleuls);
      this.filleulsData.filterPredicate = function (record,filter) {
        return record.personne.fullName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      }
    });
    this.service.getResponsables().subscribe((resps: Personne[]) => {
      this.responsables = resps;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientsData.filter = filterValue.trim().toLowerCase();
  }

  executeTransfert() {
    const execution: Execution = {
      validationId: this.transfert.validationId,
      responsableInovea: this.transfert.responsableInovea.id,
      correctionsClients: this.clientsSelection.selected.map((val: ValidationTransfertClient) => { return {validationId: this.transfert.validationId, validationPersonneId: val.id } }),
      correctionsFilleuls: [],
      signatures: this.file64,
    }
    console.log(this.form.get('envoiMailCedant')?.value);
    const execWithMail: ExecutionWithMail = {
      execution: execution,
      envoiMailCedant: this.form.get('envoiMailCedant')?.value,
      envoiMailCessionaire: this.form.get('envoiMailCessionaire')?.value,
    }

    this.service.postExecution(execWithMail).subscribe((response: any) => {
      if (response.success) {
        this.SnackBar.open("La tache à bien été exécutée !", "Fermer")
        this.isExecuted = true;
        var validationButton = document.getElementById("validationButton") as HTMLButtonElement;
        if(this.isExecuted == true){
          validationButton.style.visibility = "hidden";
        }
      } else {
        this.SnackBar.open("Echec de l'execution de la tache", "Fermer")
      }
    })
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      console.log(file.name);

      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file64 = reader.result as string;
      };
    }
    this.file64 = '';
    this.fileName = '';
  }
}
