import { Router, Routes } from '@angular/router';
import { Observable } from 'rxjs';
import { TransfertService } from 'src/app/transfert.service';
import { Personne } from './../models/personne';
import { ValidationTransfert } from './../models/validation-transfert';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { getRangeLabelFr } from 'src/app/helpers';
import { NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is the format showing on the input element
    monthYearLabel: 'MMMM YYYY', // this is showing on the calendar
  },
};

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  providers:[
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ValidationComponent implements OnInit {
  public form: FormGroup;
  public pagesSize: number[] = [20, 10, 50];

  clientsSelection = new SelectionModel<Personne>(true, []);
  clientsDisplayedColumns: string[] = ['select', 'fullName', 'identifiant'];
  public clientsData = new MatTableDataSource<Personne>();
  @ViewChild('pagCli') clientsPaginator!: MatPaginator;
  clientsFilter: string = '';

  filleulsSelection = new SelectionModel<Personne>(true, []);
  filleulsDisplayedColumns: string[] = ['select', 'fullName', 'identifiant'];
  public filleulsData = new MatTableDataSource<Personne>();
  @ViewChild('pagFi') filleulsPaginator!: MatPaginator;
  filleulsFilter: string = '';

  @ViewChild('stepper', { static: false }) stepper !: MatStepper;

  responsables: Personne[] = [];
  responsableFiltre: Observable<Personne[]>;

  commerciauxEmetteurs: Personne[] = [];

  commerciauxBeneficiaires: Personne[] = [];

  selectedResponsable: string = '';

  nextMonth = moment().add(1, 'month').date(1);
  currentMonth = moment().date(1);
  today = moment().startOf('day');

  errorMessageValidation :string|null = null;

  constructor(
    private fb: FormBuilder,
    private service: TransfertService,
    private SnackBar: MatSnackBar,
    private _matStepperIntl: MatStepperIntl,
    private _matPaginatorIntl: MatPaginatorIntl,
    private datePipe: DatePipe,
    private router: Router
    ) {
    _matPaginatorIntl.getRangeLabel = getRangeLabelFr;
    _matPaginatorIntl.itemsPerPageLabel = "éléments par page: ";
    _matStepperIntl.optionalLabel = "Optionnel";
    this.form = this.initForm();
    this.service.getResponsables().subscribe((resps: Personne[]) => {
      this.responsables = resps;
    });
    this.responsableFiltre = this.form.get("responsableFormGroup.responsableInovea")!.valueChanges
      .pipe(
        startWith(''),
        map((value: Personne) => typeof value === 'string' ? value : value.fullName),
        map(val => val ? this._filterResp(val as string) : this.responsables.slice())
      )

  }

  ngOnInit(): void {
  }

  /**
   * init form
   */
  private initForm() {
    return this.fb.group({
      responsableFormGroup: this.fb.group({
         responsableInovea: [null, [Validators.required]],
      }),
      emetteurFormGroup: this.fb.group({
        commercialEmetteur: [null, Validators.required],
      }),
      beneficiaireFormGroup: this.fb.group({
        commercialBeneficiaire: [null, Validators.required],
      }),
      repertoireFormGroup: this.fb.group({
        repertoire: [[]],
      }),
      equipeFormGroup: this.fb.group({
        equipe: [[]],
      }),
      dateComptableFormGroup: this.fb.group({
        dateComptable: [null, Validators.required],
      }),
    });
  }

  fetchCommerciauxEmetteurs(event: Event) {
    const val = (event.target as HTMLInputElement).value;
    if (val.length >= 3) {
      this.service.getCommerciaux(val).subscribe((commerciaux: Personne[]) => {
        this.commerciauxEmetteurs = commerciaux;
      });
    } else {
      this.commerciauxEmetteurs = [];
    }
  }

  fetchCommerciauxBeneficiaires(event: Event) {
    const val = (event.target as HTMLInputElement).value;
    if (val.length >= 3) {
      this.service.getCommerciaux(val).subscribe((commerciaux: Personne[]) => {
        this.commerciauxBeneficiaires = commerciaux;
      });
    } else {
      this.commerciauxBeneficiaires = [];
    }
  }

  loadClientsAndFilleuls() {
    const emetteur: Personne = this.form.controls.emetteurFormGroup.value.commercialEmetteur;
    this.service.getClients(emetteur.id).subscribe((clnts: Personne[]) => {
      this.clientsData = new MatTableDataSource<Personne>(clnts);
      this.clientsData.paginator = this.clientsPaginator;
    });
    this.service.getFilleuls(emetteur.id).subscribe((flls: Personne[]) => {
      this.filleulsData = new MatTableDataSource<Personne>(flls);
      this.filleulsData.paginator = this.filleulsPaginator;
    });

    this.stepper.next();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection: SelectionModel<Personne>, dataSource: MatTableDataSource<Personne>) {
    const numSelected = selection.selected.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
  }
  isSelectedPage(selection: SelectionModel<Personne>, dataSource: MatTableDataSource<Personne>) {
    const numSelected = selection.selected.length;
    const page = dataSource.paginator ? dataSource.paginator.pageSize : 5;
    let endIndex: number;
    // First check whether data source length is greater than current page index multiply by page size.
    // If yes then endIdex will be current page index multiply by page size.
    // If not then select the remaining elements in current page only.
    if (dataSource.data.length > ((dataSource.paginator ? dataSource.paginator.pageIndex : 0) + 1) * page) {
      endIndex = ((dataSource.paginator ? dataSource.paginator.pageIndex : 0) + 1) * page;
    } else {
      // tslint:disable-next-line:max-line-length
      endIndex = dataSource.data.length - ((dataSource.paginator ? dataSource.paginator.pageIndex : 0) * page);
    }
    return numSelected === endIndex;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection: SelectionModel<Personne>, dataSource: MatTableDataSource<Personne>) {
    this.isAllSelected(selection, dataSource) ?
      selection.clear() :
      dataSource.data.forEach((row: Personne) => selection.select(row));
  }


  public sendValidation() {
    const validation: ValidationTransfert = {
      responsableInovea: this.form.controls.responsableFormGroup.value.responsableInovea.id,
      commercialEmetteur: this.form.controls.emetteurFormGroup.value.commercialEmetteur.id,
      commercialBeneficiaire: this.form.controls.beneficiaireFormGroup.value.commercialBeneficiaire.id,
      equipe: this.filleulsSelection.selected.map(filleul => filleul.id),
      repertoire: this.clientsSelection.selected.map(client => client.id),
      dateComptable: this.datePipe.transform(this.form.controls.dateComptableFormGroup.value.dateComptable.startOf('day').toDate(), "YYYY-MM-dd")??this.today.toString()
    }

    this.service.postValidation(validation).subscribe((response: any) => {
      if (response.success) {
        this.SnackBar.open("Demande de validation envoyée !", "Fermer")
        this.router.navigate(['menu'])
      } else {
        this.SnackBar.open("Echec de l'envoi de la validation", "Fermer")
      }
    },
    (error) => {
      this.errorMessageValidation = error.error.Message
    } )
  }

  applyFilter<T>(event: Event, dataSource: MatTableDataSource<T>) {
    const filterValue = (event.target as HTMLInputElement).value;
    dataSource.filter = filterValue.trim().toLowerCase();
  }

  log(val: any) { console.log(val); }

  displayPersonne(p: Personne) {
    return p?.fullName;
  }

  private _filterResp(name: string): Personne[] {
    const filterValue = name.toLowerCase();

    return this.responsables.filter(option => option.fullName != null && option.fullName.toLowerCase().includes(filterValue));
  }

  /**
   * Get form control
   */
  get EmeteurConsultant(){
    return this.form.get("emetteurFormGroup")?.get('commercialEmetteur');
  }

  /**
   * Get form control
   */
  get BeneficiareConsultant(){
    return this.form.get("beneficiaireFormGroup")?.get('commercialBeneficiaire');
  }

  IsConsultant(consultant: Personne|null|undefined){
    if(!consultant)
    return false;

  return consultant.isConsultant;
  }
}


