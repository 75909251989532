<mat-card>
  <div class="button-row">
    <button mat-raised-button routerLink="/operation">Retour à la liste des demandes de transfert</button>
  </div>
  <mat-card-title>
    <mat-card-title>
      Responsable:
    </mat-card-title>
    <mat-card-content>
      {{transfert?.responsableInovea?.fullName}}
    </mat-card-content>

    <mat-card-title>

      Commercial cédant:
    </mat-card-title>
    <mat-card-content>
      {{transfert?.commercialEmetteur?.fullName}}
    </mat-card-content>

    <mat-card-title>
      Commercial cessionnaire:
    </mat-card-title>
    <mat-card-content>
      {{transfert?.commercialBeneficiaire?.fullName}}
    </mat-card-content>
  </mat-card-title>

  <mat-card-content *ngIf="clients.length>0">

    <mat-card-title>
      Clients
    </mat-card-title>
    <mat-form-field appearance="standard">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Dupont, dupont@gmail.com" #input [(ngModel)]="clientsFilter" [ngModelOptions]="{standalone: true}">
      <button *ngIf="clientsFilter" matSuffix mat-icon-button aria-label="Clear" (click)="clientsFilter='';clientsData.filter=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="clientsData">

        <ng-container matColumnDef="personne.fullName">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element"> {{element.personne.fullName}} </td>
        </ng-container>

        <ng-container matColumnDef="personne.identifiant">
          <th mat-header-cell *matHeaderCellDef> Mail </th>
          <td mat-cell *matCellDef="let element"> {{element.personne.identifiant}} </td>
        </ng-container>

        <ng-container matColumnDef="beneficiaireCompatible">
          <th mat-header-cell *matHeaderCellDef matTooltip="Il n'est pas possible de transférer un client à un auditeur">Bénéficiaire compatible</th>
          <td mat-cell *matCellDef="let element" matTooltip="Il n'est pas possible de transférer un client à un auditeur">
            <ng-container *ngIf="element.benefValide; then thenTemplate; else elseTemplate"></ng-container>
            <ng-template #thenTemplate>
              <mat-icon class="green-icon">check_circle</mat-icon>
            </ng-template>
            <ng-template #elseTemplate>
              <mat-icon class="red-icon">cancel</mat-icon>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="pasDImmoEnCours">
          <th mat-header-cell *matHeaderCellDef> Pas de dossier immobilier en cours </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.immoValide; then thenTemplate; else elseTemplate"></ng-container>
            <ng-template #thenTemplate>
              <mat-icon class="green-icon">check_circle</mat-icon>
            </ng-template>
            <ng-template #elseTemplate>
              <mat-icon class="red-icon">cancel</mat-icon>
            </ng-template>
          </td>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef matTooltip="Si un document l'autorisant a été signé, il est possible de forcer le transfert d'un client dont un dossier immobilier est en cours"> Document de transfert immobilier signé </th>
          <td mat-cell *matCellDef="let row" matTooltip="Si un document l'autorisant a été signé, il est possible de forcer le transfert d'un client dont un dossier immobilier est en cours">
            <ng-container *ngIf="row.immoValide; then thenSelect; else elseSelect"></ng-container>
            <ng-template #thenSelect>
              <mat-checkbox disabled></mat-checkbox>
            </ng-template>
            <ng-template #elseSelect>
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? clientsSelection.toggle(row) : null" [checked]="clientsSelection.isSelected(row)">
              </mat-checkbox>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="clientsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: clientsDisplayedColumns;">
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="pagesSize" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>


  <mat-card-content *ngIf="filleuls.length>0">

    <mat-card-title>
      Filleuls
    </mat-card-title>
    <mat-form-field appearance="standard">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Dupont, dupont@gmail.com" #input [(ngModel)]="filleulsFilter" [ngModelOptions]="{standalone: true}">
      <button *ngIf="filleulsFilter" matSuffix mat-icon-button aria-label="Clear" (click)="filleulsFilter='';filleulsData.filter=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="filleulsData" *ngIf="filleuls != []">

        <ng-container matColumnDef="personne.fullName">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element"> {{element.personne.fullName}} </td>
        </ng-container>

        <ng-container matColumnDef="personne.identifiant">
          <th mat-header-cell *matHeaderCellDef> Mail </th>
          <td mat-cell *matCellDef="let element"> {{element.personne.identifiant}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="filleulsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: filleulsDisplayedColumns;">
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="pagesSize" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>

  <form [formGroup]="form">


    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload accept=".pdf">

    <div class="file-upload">

      {{fileName}}
      <!-- {{fileName || "No file uploaded yet."}} -->

      <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
        <mat-icon> attach_file</mat-icon>
      </button>
    </div>
    <div class="form-group__input">
      <mat-checkbox name="envoiMailCedant" id="envoiMailCedant" formControlName="envoiMailCedant" >Envoi du mail au cédant</mat-checkbox>
      <br/>
      <mat-checkbox name="envoiMailCessionaire" id="envoiMailCessionaire" formControlName="envoiMailCessionaire" >Envoi du mail au cessionnaire</mat-checkbox>
    </div>
  </form>
  <div class="button-row">
    <button mat-raised-button routerLink="/operation">Retour à la liste des demandes de transfert</button>
    <button *ngIf="!transfert?.isExecuted" id="validationButton" mat-raised-button color="primary" type="submit" (click)="executeTransfert()">Lancer l'éxecution du transfert</button>
    <p *ngIf="transfert?.isExecuted">Le transfert a déjà été éxécuté</p>
  </div>
</mat-card>
