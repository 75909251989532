import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationTransfert, TransfertARealiser, ValidationTransfertClient, ValidationTransfertContact, ExecutionWithMail } from './models/validation-transfert';
import { Observable } from 'rxjs';
import { Personne } from './models/personne';
import { ConfigService } from './services/config.service'

@Injectable({
  providedIn: 'root'
})
export class TransfertService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getFilleuls(emetteurId: string): Observable<Personne[]> {
    return this.http.get<Personne[]>(this.configService.config["httpBackEnd"] + 'api/transfert/filleuls?commercial=' + emetteurId);
  }
  getClients(emetteurId: string): Observable<Personne[]> {
    return this.http.get<Personne[]>(this.configService.config["httpBackEnd"] + 'api/transfert/clients?commercial=' + emetteurId);
  }

  public getResponsables(): Observable<Personne[]> {
    return this.http.get<Personne[]>(this.configService.config["httpBackEnd"] + 'api/transfert/responsables');
  }

  getCommerciaux(token: string): Observable<Personne[]> {
    return this.http.get<Personne[]>(this.configService.config["httpBackEnd"] + 'api/transfert/commerciaux?token=' + token);
  }

  public postValidation(dto: ValidationTransfert): Observable<ValidationTransfert> {
    return this.http.post<ValidationTransfert>(this.configService.config["httpBackEnd"] + 'api/transfert/validation', dto);
  }

  public getValidations(): Observable<TransfertARealiser[]> {
    return this.http.get<TransfertARealiser[]>(this.configService.config["httpBackEnd"] + 'api/transfert/validations')
  }

  public getValidation(id: string): Observable<TransfertARealiser> {
    return this.http.get<TransfertARealiser>(this.configService.config["httpBackEnd"] + 'api/transfert/validation?id=' + id)
  }

  getClientsValidation(id: string): Observable<ValidationTransfertClient[]> {
    return this.http.get<ValidationTransfertClient[]>(this.configService.config["httpBackEnd"] + 'api/transfert/validation/' + id + '/clients')
  }

  getFilleulsValidation(id: string): Observable<ValidationTransfertContact[]> {
    return this.http.get<ValidationTransfertContact[]>(this.configService.config["httpBackEnd"] + 'api/transfert/validation/' + id + '/filleuls')
  }

  public postExecution(dto: ExecutionWithMail): Observable<boolean> {
    return this.http.post<boolean>(this.configService.config["httpBackEnd"] + 'api/transfert/execution', dto);
  }
}
