import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'transfert';
  config$!: Observable<any>;

  constructor(private configService: ConfigService) { }

  ngOnInit() {
    this.getConfig();
  }

  public getConfig() {

    this.config$ = this.configService.loadConfig();

    this.config$.subscribe({

      next: (data) => {

        this.configService.config = data;

      }

    })

  }
}

