<mat-card>
  <h1>Validation ou exécution</h1>
  <p>
    Lors d'une "Validation d'une demande de transfert", on choisit le cédant, cessionnaire ainsi que tous les contacts/prospects/clients et les filleuls à transférer.
  </p>
  <p>
    Lors de "Exécution d'un transfert", on peut vérifier que les personnes impliquées sont bien compatible avec la demande de validation de transfert. Une fois la relecture des informations du transfert effectuée, on peut lancer l'exécution effective du transfert.
  </p>
  <div class="button-row">
    <button mat-raised-button color="primary" routerLink="/validation">Validation d'une demande de transfert</button>
    <button mat-raised-button color="accent" routerLink="/operation">Exécution d'un transfert</button>
  </div>
</mat-card>
