// export function getRangeLabelFr(page: number, pageSize: number, length: number) {
//  return pageSize + " éléments dans la page " + page + " sur " + length  + " pages"
// }

export function getRangeLabelFr(page: number, pageSize: number, length: number): string {
  const of = "parmi";
  if (length === 0 || pageSize === 0) {
    return "0 " + of + " " + length;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

  const endIndex = Math.min(startIndex + pageSize, length);
  return "éléments " + (startIndex + 1) + " à " + endIndex + " " + of + " " + length;
};
