import { MenuComponent } from './menu/menu.component';
import { AppComponent } from './app.component';
import { OperationComponent } from './operation/operation.component';
import { ValidationComponent } from './validation/validation.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './operation/details/details.component';

const routes: Routes = [
  { path: '', redirectTo: '/menu', pathMatch: 'full' },
  { path: 'menu', component: MenuComponent },
  { path: 'validation', component: ValidationComponent },
  { path: 'operation', component: OperationComponent },
  { path: 'operation/:id', component: DetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
