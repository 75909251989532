<mat-sidenav-container *ngIf="config$ | async">
  <mat-sidenav #sidenav mode="side">
    <mat-nav-list>
      <a mat-list-item routerLink="">Menu</a>
      <a mat-list-item routerLink="/validation">Validation</a>
      <a mat-list-item routerLink="/operation">Exécution</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Transfert</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
