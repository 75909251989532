<mat-card>
  <form [formGroup]="form">

    <mat-stepper linear="true" orientation="vertical" #stepper>

      <mat-step [stepControl]="form.controls.responsableFormGroup" [formGroupName]="'responsableFormGroup'">
        <ng-template matStepLabel>Sélection du responsable du transfert</ng-template>
        <mat-form-field appearance="fill">
          <mat-label>Responsable INOVEA</mat-label>
          <input matInput formControlName="responsableInovea" required [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPersonne">
            <mat-option *ngFor="let option of responsableFiltre | async" [value]="option">
              {{option.fullName}} {{option.identifiant}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-icon matTooltip="L'employé responsable du transfert">help_outline</mat-icon>
        <div>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.controls.emetteurFormGroup" [formGroupName]="'emetteurFormGroup'" (selectionChange)="loadClientsAndFilleuls()">
        <ng-template matStepLabel>Sélection du commercial cédant</ng-template>
        <mat-form-field appearance="fill">
          <mat-label>Commercial cédant</mat-label>
          <input matInput formControlName="commercialEmetteur" [matAutocomplete]="autoEm" (keyup)="fetchCommerciauxEmetteurs($event)">
          <mat-autocomplete #autoEm="matAutocomplete" [displayWith]="displayPersonne">
            <mat-option *ngFor="let option of commerciauxEmetteurs" [value]="option">
              {{option.fullName}} {{option.identifiant}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-icon matTooltip="L'auditeur ou consultant qui transmet des membres de son portefeuille et/ou organisation">help_outline</mat-icon>
        
        <div>
          <button mat-button matStepperPrevious>Précédent</button>
          <button mat-button (click)="loadClientsAndFilleuls()">Suivant</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.controls.beneficiaireFormGroup" [formGroupName]="'beneficiaireFormGroup'" (click)="loadClientsAndFilleuls()">
        <ng-template matStepLabel>Sélection du commercial Beneficiaire</ng-template>
        <mat-form-field appearance="fill">
          <mat-label>Commercial Beneficiaire</mat-label>
          <input matInput formControlName="commercialBeneficiaire" [matAutocomplete]="autoBen" (keyup)="fetchCommerciauxBeneficiaires($event)">
          <mat-autocomplete #autoBen="matAutocomplete" [displayWith]="displayPersonne">
            <mat-option *ngFor="let option of commerciauxBeneficiaires" [value]="option">
              {{option.fullName}} {{option.identifiant}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-icon matTooltip="L'auditeur ou consultant qui reçoit le repertoire et/ou organisation">help_outline</mat-icon>
        <div>
          <button mat-button matStepperPrevious>Précédent</button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.controls.repertoireFormGroup" [formGroupName]="'repertoireFormGroup'" [optional]="true">
        <ng-template matStepLabel>Sélection des contacts du repertoire à tranférer</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event, clientsData)" placeholder="Ex. Dupont, dupont@gmail.com" #input [(ngModel)]="clientsFilter" [ngModelOptions]="{standalone: true}">
          <button *ngIf="clientsFilter" matSuffix mat-icon-button aria-label="Clear" (click)="clientsFilter='';clientsData.filter=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-icon matTooltip="La liste des contacts/prospects/clients (repertoire) du cédant à transmettre au bénéficiare">help_outline</mat-icon>
        <button mat-icon-button class="reload" (click)="loadClientsAndFilleuls()">
          <mat-icon matTooltip="Recharger la liste des contacts/prospects/clients">autorenew</mat-icon>
        </button>
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="clientsData">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle(clientsSelection, clientsData) : null" [checked]="clientsSelection.hasValue() && isAllSelected(clientsSelection, clientsData)" [indeterminate]="clientsSelection.hasValue() && !isAllSelected(clientsSelection, clientsData)">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? clientsSelection.toggle(row) : null" [checked]="clientsSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Nom Column -->
            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef> Nom </th>
              <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="identifiant">
              <th mat-header-cell *matHeaderCellDef> Adresse mail </th>
              <td mat-cell *matCellDef="let element"> {{element.identifiant}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="clientsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: clientsDisplayedColumns;" (click)="clientsSelection.toggle(row)">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="pagesSize" showFirstLastButtons #pagCli></mat-paginator>
        </div>
        <div>
          <button mat-button matStepperPrevious>Précédent</button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.controls.equipeFormGroup" [formGroupName]="'equipeFormGroup'" [optional]="true">
        <ng-template matStepLabel>Sélection des filleuls à tranférer</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event, filleulsData)" placeholder="Ex. Dupont, dupont@gmail.com" #input [(ngModel)]="filleulsFilter" [ngModelOptions]="{standalone: true}">
          <button *ngIf="filleulsFilter" matSuffix mat-icon-button aria-label="Clear" (click)="filleulsFilter='';filleulsData.filter=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-icon matTooltip="La liste des filleuls (organisation) du cédant à transmettre au cessionnaire">help_outline</mat-icon>
        <button mat-icon-button class="reload" (click)="loadClientsAndFilleuls()">
          <mat-icon matTooltip="Recharger la liste des filleuls">autorenew</mat-icon>
        </button>
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="filleulsData">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle(filleulsSelection, filleulsData) : null" [checked]="filleulsSelection.hasValue() && isAllSelected(filleulsSelection, filleulsData)" [indeterminate]="filleulsSelection.hasValue() && !isAllSelected(filleulsSelection, filleulsData)">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? filleulsSelection.toggle(row) : null" [checked]="filleulsSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Nom Column -->
            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef> Nom </th>
              <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="identifiant">
              <th mat-header-cell *matHeaderCellDef> Adresse mail </th>
              <td mat-cell *matCellDef="let element"> {{element.identifiant}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="filleulsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: filleulsDisplayedColumns;" (click)="filleulsSelection.toggle(row)">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="pagesSize" showFirstLastButtons #pagFi></mat-paginator>
        </div>
        <div>
          <button mat-button matStepperPrevious>Précédent</button>
          <button mat-button matStepperNext>Suivant</button>
        </div>
      </mat-step>


      <mat-step [stepControl]="form.controls.dateComptableFormGroup" [formGroupName]="'dateComptableFormGroup'">
        <ng-template matStepLabel>Sélection de la date comptable</ng-template>
        <mat-form-field appearance="fill">
          <mat-label>Date comptable</mat-label>
          <input matInput formControlName="dateComptable" [matDatepicker]="picker" [min]="today">
          <!-- <input matInput formControlName="dateComptable" [matDatepicker]="picker" [min]="nextMonth">
          fix pour effectuer les transferts en retard à posteriori -->
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="month" [startAt]="today.toDate()"></mat-datepicker>
        </mat-form-field>
        <mat-icon matTooltip="La date à partir de laquelle le transfert sera pris en compte comptablement, ne doit pas execéder la date du jour">help_outline</mat-icon>
        <div>
          <button mat-button matStepperPrevious>Précédent</button>
        </div>
      </mat-step>

    </mat-stepper>

    <button mat-raised-button type="submit" [disabled]="!form.valid" (click)="sendValidation()">
      Valider la demande de transfert
    </button>


    <div *ngIf="errorMessageValidation">
        <p class="errorMessage" [innerHTML]="errorMessageValidation "> </p>
    </div>
  </form>
</mat-card>
